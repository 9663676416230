@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Nunito", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
